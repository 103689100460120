function Preload() {
    return ( 
        <div className="preeee">
        <div className="containerssss" id="spinner">
            <div className="loadingghgh"></div>
            <div className="loadingghgh"></div>
            <div className="loadingghgh"></div>
            <small>Loading...</small>
        </div>
        </div>
     );
}

export default Preload;